import PropTypes from 'prop-types'
// material
import { Box } from '@mui/material'

// ----------------------------------------------------------------------

LogoCapes.propTypes = {
  sx: PropTypes.object
}

export default function LogoCapes({ sx }) {
  return <Box component="img" src="/static/logoCapes.png" sx={{ width: 110, ...sx }} />
}
