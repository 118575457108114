/* eslint-disable camelcase */
import { useState, useEffect } from 'react'
import { CSVLink } from 'react-csv'
import UploadIcon from '@mui/icons-material/Upload'
import DownloadIcon from '@mui/icons-material/Download'
import { Link as RouterLink, useLocation } from 'react-router-dom'
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress
} from '@mui/material'
// components
import { useSnackbar } from 'notistack'
import Page from '../components/Page'
import Scrollbar from '../components/Scrollbar'
import SearchNotFound from '../components/SearchNotFound'
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/egressos'
//
import { egressoList } from '../_mocks_/egressoList'
import { deleteEgresso, getEgressos } from '../components/_dashboard/egressos/auth/requests'
import replaceValueWith from '../utils/replaceValueWith'
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'full_name', label: 'Nome', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'masters_degree_year', label: 'Ano Mestrado', alignRight: false },
  { id: 'doctorate_degree_year', label: 'Ano Doutorado', alignRight: false },
  { id: 'job_area', label: 'Área', alignRight: false },
  { id: 'local', label: 'Local Trabalho', alignRight: false },
  { id: '' }
]

const headersCSV = [
  { label: 'Nome', key: 'full_name' },
  { label: 'Email', key: 'email' },
  { label: 'Ano Mestrado', key: 'masters_degree_year' },
  { label: 'Ano Doutorado', key: 'doctorate_degree_year' },
  { label: 'Área', key: 'job_area' },
  { label: 'Local Trabalho', key: 'local' }
]

export default function Egressos() {
  const location = useLocation()
  const [page, setPage] = useState(location?.state?.page || 0)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('full_name')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [egressos, setEgressos] = useState([])
  const [loading, setLoading] = useState(true)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [isEgressos, setIsEgressos] = useState(true)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [itemId, setItemId] = useState(null)
  const [successDelete, setSucessDelete] = useState(false)
  const [totalPage, setTotalPage] = useState(0)
  const filteredEgressos = replaceValueWith(egressos, '')

  const { enqueueSnackbar } = useSnackbar()

  const handleSnackBar = (message, variant) => {
    enqueueSnackbar(message, {
      variant
    })
  }

  const data = async () => {
    try {
      setLoading(true)
      const dataEgressos = await getEgressos(page + 1, rowsPerPage, orderBy, order, filterName)
      const { total } = dataEgressos
      const egre = await egressoList(dataEgressos.data)
      setTotalPage(total)
      setEgressos(egre)
      setIsEgressos(true)
      if (egre.length === 0) {
        setIsEgressos(false)
      }
      setLoading(false)
    } catch (error) {
      if (filterName) {
        setIsEgressos(false)
      } else {
        handleSnackBar('Não foi possível carregar os egressos', 'error')
      }
      setLoading(false)
      setEgressos([])
    }
  }
  useEffect(() => {
    data()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successDelete, page, rowsPerPage, orderBy, order])

  // Search when user stops typing
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      data()
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterName])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilterByName = (event) => {
    setIsEgressos(true)
    if (!event.target.value) setIsEgressos(true)
    setFilterName(event.target.value)
  }

  const handleDeleteEgresso = async (id) => {
    setDeleteLoading(true)
    try {
      await deleteEgresso(id)
      setLoading(true)
      setSucessDelete(!successDelete)
      setDialogOpen(false)
      handleSnackBar('Egresso excluído com sucesso!', 'success')
      setDeleteLoading(false)
    } catch (error) {
      setDialogOpen(false)
      setDeleteLoading(false)
      handleSnackBar('Erro ao excluir egresso', 'error')
    }
  }

  const handleDialog = (id) => {
    setDialogOpen(true)
    setItemId(id)
  }

  const handleDialogClose = () => {
    setItemId(null)
    setDialogOpen(false)
  }

  return (
    <Page title="Egressos | Portal Egressos - UFV">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Egressos
          </Typography>
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button variant="contained" component={RouterLink} to="importar" startIcon={<UploadIcon />}>
              Importar
            </Button>
            <CSVLink
              data={filteredEgressos}
              headers={headersCSV}
              style={{ textDecoration: 'none', cursor: !egressos.length ? 'default' : 'cursor' }}
              filename="egressos"
              onClick={() => {
                if (!egressos.length || loading) return false
                return true
              }}
            >
              <Button disabled={!filteredEgressos.length || loading} variant="contained" startIcon={<DownloadIcon />}>
                Exportar
              </Button>
            </CSVLink>
          </div>
        </Stack>

        <Card>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={egressos.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {!loading &&
                    egressos.map((row) => {
                      const {
                        id,
                        full_name,
                        masters_degree_year,
                        job_area,
                        local,
                        email,
                        doctorate_degree_year,
                        status
                      } = row
                      return (
                        <TableRow
                          key={id}
                          style={
                            status === 0 ? { borderLeft: '8px solid #bbbbbb' } : { borderLeft: '8px solid #14bb00' }
                          }
                        >
                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {full_name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{masters_degree_year}</TableCell>
                          <TableCell align="left">{doctorate_degree_year}</TableCell>
                          <TableCell align="left">{job_area}</TableCell>
                          <TableCell align="left">{local !== 'null' && local}</TableCell>

                          <TableCell align="right">
                            <UserMoreMenu
                              handleDialogClose={handleDialogClose}
                              handleDeleteEgresso={handleDeleteEgresso}
                              loading={deleteLoading}
                              dialogOpen={dialogOpen}
                              itemId={itemId}
                              handleOpen={handleDialog}
                              id={id}
                              userId={id}
                              page={page}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {!isEgressos && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 30, 50, 100, 500]}
            component="div"
            count={totalPage}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Linhas por página:"
            labelDisplayedRows={({ from, to, count }) => `${from} - ${to} de ${count}`}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  )
}
