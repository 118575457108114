import PropTypes from 'prop-types'
import { Card, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { MHidden } from '../components/@material-extend'
import AuthLayout from './AuthLayout'
import LogoCapes from '../components/LogoCapes'
import LogoBrasaoUFV from '../components/LogoBrasaoUFV'

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 364,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}))

const FooterStyle = styled(Grid)(({ theme }) => ({
  bottom: 40,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(7, 5, 0, 7)
  }
}))

AuthSidebar.propTypes = {
  children: PropTypes.node
}

export default function AuthSidebar({ children }) {
  return (
    <MHidden width="mdDown">
      <SectionStyle>
        <AuthLayout />
        {children}
        <FooterStyle component="footer" container justifyContent="center" spacing={4}>
          <Grid container item xs={12} justifyContent="center">
            <LogoCapes />
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <LogoBrasaoUFV />
          </Grid>
        </FooterStyle>
      </SectionStyle>
    </MHidden>
  )
}
