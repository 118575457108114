import React, { createContext, useContext, useState, useEffect, useCallback } from 'react'
import Cookies from 'universal-cookie'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import { getUserInfo } from '../api/me'
import LoadingPage from '../components/LoadingPage'
import { login } from '../api/auth'

const MeContext = createContext()

export const MeProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({ name: '', email: '' })
  const [egressoData, setEgressoData] = useState([])
  const [meLoading, setMeLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [credentialsError, setCredentialsError] = useState(false)

  const [isAuth, setIsAuth] = useState(false)

  const [role, setRole] = useState('')

  const navigate = useNavigate()
  const location = useLocation()

  const fetchUserData = async () => {
    try {
      setMeLoading(true)
      const user = await getUserInfo()
      setIsAuth(true)
      const info = user.data
      const { name, email } = info.user
      setUserInfo({ name, email })
      setEgressoData(info.egresso || [])
      setRole(info.role)
      setMeLoading(false)
    } catch (error) {
      setMeLoading(false)
      setIsAuth(false)
      setRole('')
      navigate('/mapa', { replace: true, state: { from: location } })
    }
  }

  const loginRequest = async (email, password) => {
    setCredentialsError(false)
    try {
      const resp = await login(email, password)
      const token = resp.access.access_token

      // Tempo de expiração do cookie
      const maxAge = resp.access.expires_in

      const cookies = new Cookies()
      if (token) {
        cookies.set('token', token, { path: '/', maxAge })
      }
      setIsAuth(true)
    } catch (error) {
      if (error?.response?.status === 401) {
        setCredentialsError(true)
      }
    }
  }

  const logout = () => {
    const cookies = new Cookies()
    cookies.remove('token', { path: '/' })
    setRole('')
    setIsAuth(false)
    navigate('/login', { replace: true })
  }

  useEffect(() => fetchUserData(), [refresh, isAuth])

  if (meLoading) {
    return <LoadingPage />
  }

  const value = {
    userInfo,
    setUserInfo,
    egressoData,
    meLoading,
    setRefresh,
    isAuth,
    role,
    setRole,
    loginRequest,
    logout,
    credentialsError
  }
  return (
    <div>
      <MeContext.Provider value={value}>{children}</MeContext.Provider>
    </div>
  )
}

export const useMe = () => {
  return useContext(MeContext)
}
