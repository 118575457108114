import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useFormik, Form, FormikProvider } from 'formik'
import { Icon } from '@iconify/react'
import eyeFill from '@iconify/icons-eva/eye-fill'
import eyeOffFill from '@iconify/icons-eva/eye-off-fill'
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel, Alert } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import Cookies from 'universal-cookie'
import { login } from '../../../api/auth'
import { useMe } from '../../../Context/MeContext'
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate()
  const { loginRequest, role, isAuth, credentialsError } = useMe()
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (isAuth && role === 'admin') navigate('/app/dashboard')
    if (isAuth && role === 'egresso') navigate('/app/profile')
  }, [])

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('O e-mail deve ser um endereço de e-mail válido').required('E-mail obrigatório'),
    password: Yup.string().required('Senha obrigatória')
  })
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      const { email, password } = values
      await loginRequest(email, password)
    }
  })

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik

  const handleShowPassword = () => {
    setShowPassword((show) => !show)
  }

  return (
    <FormikProvider value={formik}>
      {credentialsError && (
        <Alert variant="filled" severity="error" style={{ margin: '32px 0' }}>
          E-mail e/ou senha inválidos!
        </Alert>
      )}
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="E-mail"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Senha"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Lembrar-me"
          />

          <Link component={RouterLink} variant="subtitle2" to="/esqueci-senha">
            Esqueceu a senha?
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Entrar
        </LoadingButton>
      </Form>
    </FormikProvider>
  )
}
