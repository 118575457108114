import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useMe } from '../Context/MeContext'

function RequireAuth({ allowedRoles }) {
  const { role } = useMe()
  const location = useLocation()

  const checkRole = () => {
    if (allowedRoles.includes(role)) {
      return <Outlet />
    }
    if (!allowedRoles.includes(role)) {
      return <Navigate to="/unauthorized" state={{ from: location }} replace />
    }
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return checkRole()
}

export default RequireAuth
