import React from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { Typography } from '@mui/material'
// Color of the dropzone when the user dragges a file
const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isFocused) {
    return '#2196f3'
  }
  return '#eeeeee'
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`

function Dropzone({ getFiles, maxFiles = 1 }) {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
    accept: {
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'text/csv': []
    },
    maxFiles,
    onDrop: (files) => {
      getAcceptedFiles(files)
    }
  })

  // File list on dropzone
  const files = acceptedFiles.map((file) => <li key={file.path}>{file.path}</li>)

  // Send accepted files through props to the parent component
  function getAcceptedFiles(files) {
    getFiles(files)
  }
  return (
    <div style={{ margin: '15px' }}>
      <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })} style={{ cursor: 'pointer' }}>
        <input {...getInputProps()} />
        <p>Arraste ou clique aqui para selecionar o arquivo.</p>
      </Container>
      <Typography variant="caption" display="block" gutterBottom color="primary.main" mt={1}>
        {`É permitido apenas ${maxFiles} arquivo${maxFiles > 1 ? 's' : ''} por vez.`}
      </Typography>
      {files}
    </div>
  )
}

export default Dropzone
