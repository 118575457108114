import React from 'react'

import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'

import { Autocomplete, TextField } from '@mui/material'
import handleInputChange from './hooks/handleInputChange'

const GoogleSearch = ({
  setFieldValue,
  helperText,
  error,
  jobCity,
  jobState,
  jobCountry,
  edit,
  setTrabalhaExterior,
  disabled
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete()

  return (
    <div className="search">
      <Autocomplete
        disabled={disabled}
        disablePortal
        options={data}
        defaultValue={
          edit && { description: jobCountry === 'Brasil' ? `${jobCity}-${jobState}, ${jobCountry}` : `${jobCountry}` }
        }
        getOptionLabel={(option) => option?.description}
        onInputChange={(e) => {
          setValue(e?.target.value)
        }}
        renderInput={(params) => <TextField error={error} helperText={helperText} {...params} label="Localização" />}
        onChange={async (e, newValue) => {
          await handleInputChange(newValue, setFieldValue, getDetails)
        }}
      />
    </div>
  )
}

export default GoogleSearch
