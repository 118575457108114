import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'
import { Autocomplete, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'

const SearchBox = styled(Autocomplete)(() => ({
  position: 'absolute',
  top: '1rem',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '100%',
  maxWidth: '400px',
  height: '40px',
  zIndex: 10,
  borderRadius: '20px',
  '& .MuiOutlinedInput-root': {
    background: 'white',
    borderRadius: '20px',
    padding: '0 10px'
  },
  '-webkit-box-shadow': '0px 15px 25px 4px rgba(0,0,0,0.17)',
  boxShadow: '0px 15px 25px 4px rgba(0,0,0,0.17)'
}))

const Search = ({ panTo }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete()
  return (
    <SearchBox
      disabled={!ready}
      disablePortal
      options={data}
      getOptionLabel={(option) => option?.description}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField {...params} />}
      onInputChange={(e) => setValue(e?.target?.value)}
      onChange={async (e, newValue) => {
        clearSuggestions()
        try {
          const results = await getGeocode({ address: newValue.description })
          const { lat, lng } = await getLatLng(results[0])
          panTo({ lat, lng })
        } catch (error) {
          console.warn(error)
        }
      }}
    />
  )
}

export default Search
