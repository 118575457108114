import Api from '../../../../api/api'

export const createUser = async (data) => {
  const egresso = await Api.post(`/user`, data)
  return egresso
}

export const getUsers = async (page, perPage, orderBy, orderDir, term) => {
  const users = await Api.get(
    `/user?page=${page}&per_page=${perPage}&orderBy=${orderBy}&orderDir=${orderDir}&term=${term}`
  )
  return users
}

export const getUser = async (id) => {
  const user = await Api.get(`/user/${id}`)
  return user
}

export const updateUser = async (id, data) => {
  const user = await Api.put(`/user/${id}`, data)
  return user
}

export const deleteUser = async (id) => {
  const user = await Api.delete(`/user/${id}`)
  return user
}

export const updateUserAccess = async (data) => {
  const user = await Api.put(`/me/access`, data)
  return user
}
