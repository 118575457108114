// //----- Seta o país, estado e cidade do egresso ------// //
export default async (newValue, setFieldValue, getDetails) => {
  getDetails({
    placeId: newValue?.place_id,
    fields: ['address_components', 'formatted_address', 'name', 'geometry', 'types']
  }).then((details) => {
    const country = details.address_components.filter((item) => (item.types.includes('country') ? item : ''))
    const state = details.address_components.filter((item) =>
      item.types.includes('administrative_area_level_1') ? item : ''
    )
    const city = details.address_components.filter((item) => (item.types.includes('locality') ? item : ''))
    const lng = details.geometry.location.lng()
    const lat = details.geometry.location.lat()
    setFieldValue('lng', lng)
    setFieldValue('lat', lat)
    setFieldValue('job_city', city[0]?.long_name)
    setFieldValue('job_state', state[0]?.short_name)
    setFieldValue('job_country', country[0]?.long_name)
  })
}
