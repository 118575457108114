import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, Stack, Container, Typography, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import Page from '../components/Page'
import Dropzone from '../components/_dashboard/dropzone/Dropzone'

import { sendFile } from '../components/_dashboard/dropzone/sendFile'
import snackbar from '../utils/toast'

const ImportarEgresso = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [files, setFiles] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const sendFileTo = async (endPoint, files) => {
    try {
      setIsSubmitting(true)
      await sendFile(endPoint, files)
      snackbar('Arquivo importado com sucesso!', 'success', enqueueSnackbar)
      setIsSubmitting(false)
    } catch (error) {
      setIsSubmitting(false)
      snackbar('Erro ao importar arquivo', 'error', enqueueSnackbar)
    }
  }
  return (
    <Page title="Importar Egressos | Portal Egressos - UFV">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Importar Egressos
          </Typography>
        </Stack>

        <Card style={{ padding: '30px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Anexe abaixo um arquivo com a extensão .xlsx, .xls ou .csv para a importação dos Egresso.
              </Typography>
              <Typography variant="h6" gutterBottom>
                Para que a importação seja bem sucedida seu arquivo deve seguir este{' '}
                <Link to="/static/files/Modelo_Importacao_Egressos.xlsx" download target="_blank">
                  modelo
                </Link>
                .
              </Typography>
            </Grid>
          </Grid>
          <Dropzone getFiles={(files) => setFiles(files)} maxFiles={1} />
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Grid item xs={12} />
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                onClick={() => sendFileTo('/egressos/import', files)}
                disabled={!files.length}
                loading={isSubmitting}
              >
                Importar
              </LoadingButton>
            </Stack>
          </Grid>
        </Card>
      </Container>
    </Page>
  )
}

export default ImportarEgresso
