import * as Yup from 'yup'
import React, { useEffect, useState } from 'react'
import { useFormik, Form, FormikProvider } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Card,
  Stack,
  Switch,
  Container,
  Typography,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  FormHelperText,
  Alert,
  Grid,
  Checkbox,
  FormGroup
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import { getEgressosById, editEgresso, updatePersonalEgresso } from '../components/_dashboard/egressos/auth/requests'

import GoogleSearch from '../components/googleInput/GooglePlaces'
import { useMe } from '../Context/MeContext'

export default function EditarEgresso({ inProfile = false, meLoading }) {
  const navigate = useNavigate()
  const location = useLocation()
  const { egressoData, setRefresh } = useMe()
  const [loading, setLoading] = useState(true)
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [mestrado, setMestrado] = useState('')
  const [doutorado, setDoutorado] = useState('')
  const [jobArea, setJobArea] = useState('')
  const [jobCompany, setJobCompany] = useState('')
  const [jobState, setJobState] = useState('')
  const [jobCity, setJobCity] = useState('')
  const [jobCountry, setJobCountry] = useState('')
  const [allowDisplay, setAllowDisplay] = useState(0)
  const [isEmployed, setIsEmployed] = useState('no')
  const [status, setStatus] = useState(0)
  const [reqErr, setReqErr] = useState(false)
  // State caso nao conseguir carregar o egresso
  const [reqFailed, setReqFailed] = useState(false)

  // Populate form
  const populateForm = (data) => {
    setFullName(data.full_name || '')
    setEmail(data.email || '')
    setMestrado(data.masters_degree_year || '')
    setDoutorado(data.doctorate_degree_year || '')
    setJobCompany(data.job_company || '')
    setJobArea(data.job_area || '')
    setFieldValue('job_city', data.job_city || '')
    setFieldValue('job_state', data.job_state || '')
    setFieldValue('job_country', data.job_country || '')
    setFieldValue('lng', data.longitude || 0)
    setFieldValue('lat', data.latitude || 0)

    // Dados para popular o input do GooglePlaces
    setJobCity(data.job_city || '')
    setJobState(data.job_state || '')
    setJobCountry(data.job_country || '')

    setStatus(data.status || 0)
    setIsRetired(data.is_retired)
    setAllowDisplay(data.allows_display_data_publicly)
    setIsEmployed(data.is_employed ? 'yes' : 'no')
  }

  // Popula o form caso o usuario esteja em seu perfil
  useEffect(() => {
    if (inProfile) {
      populateForm(egressoData)
    }
  }, [egressoData])

  // Permição da exibição dos dados publicamente no profile
  const handlePermission = () => {
    const perm = allowDisplay === 0 ? 1 : 0
    setAllowDisplay(perm)
    setFieldValue('allows_display_data_publicly', perm)
  }

  // is_retired
  const [isRetired, setIsRetired] = useState()
  const handleRetired = (e) => {
    if (e.target.checked) {
      setIsRetired(1)
      setFieldValue('is_retired', 1)
      setIsEmployed('no')
    } else {
      setIsRetired(0)
      setFieldValue('is_retired', 0)
      setIsEmployed('yes')
    }
  }

  // Toast de success/error
  const { enqueueSnackbar } = useSnackbar()
  const handleSnackBar = (message, variant) => {
    enqueueSnackbar(message, {
      variant
    })
  }

  useEffect(() => {
    ;(async () => {
      if (location.state?.egressoId) {
        try {
          const egresso = await getEgressosById(location.state.egressoId)
          const { data } = egresso
          setLoading(false)
          populateForm(data)
        } catch (error) {
          setLoading(false)
          setReqFailed(true)
        }
      } else {
        setLoading(false)
      }
    })()
  }, [])

  const EditSchema = Yup.object().shape({
    full_name: Yup.string(),
    email: Yup.string().email('O e-mail deve ser um endereço de e-mail válido'),
    masters_degree_year: Yup.string().min(4, 'Ano inválido').max(4, 'Ano inválido'),
    doctorate_degree_year: Yup.string().min(4, 'Ano inválido').max(4, 'Ano inválido'),
    job_area: Yup.string('').nullable(),
    job_company: Yup.string('').nullable(),
    job_state: Yup.string('').nullable(),
    job_city: Yup.string('').nullable(),
    job_country: Yup.string('').nullable()
  })

  const formik = useFormik({
    initialValues: {
      full_name: '',
      masters_degree_year: '',
      doctorate_degree_year: '',
      email: '',
      job_state: '',
      job_area: '',
      job_city: '',
      job_country: '',
      job_company: '',
      lat: 0,
      lng: 0
    },
    validationSchema: EditSchema,
    onSubmit: async () => {
      setReqErr(false)
      const valueData = {
        allows_display_data_publicly: allowDisplay,
        is_employed: jobArea === '' || isRetired === 1 ? 'N' : 'yes',
        job_country: values.job_country,
        is_retired: isRetired,
        full_name: fullName,
        masters_degree_year: mestrado,
        doctorate_degree_year: doutorado,
        email,
        job_state: values.job_state,
        job_area: jobArea,
        job_city: values.job_city,
        job_company: jobCompany,
        status,
        lng: values.lng,
        lat: values.lat
      }
      if (location.state?.egressoId) {
        try {
          await editEgresso(location.state.egressoId, valueData)
          handleSnackBar('Egresso editado com sucesso!', 'success')
          navigate('/app/egressos', { state: { page: location.state?.page }, replace: true })
        } catch (error) {
          if ([500, 502, 503, 504].includes(error.response?.status)) {
            handleSnackBar('Erro ao editar egresso!', 'error')
            setReqErr(true)
          } else {
            setErrors(error.response?.data.errors)
          }
        }
      } else {
        try {
          await updatePersonalEgresso(valueData)
          setRefresh((prev) => !prev)
          handleSnackBar('Cadastro atualizado', 'success')
        } catch (error) {
          if (!error.response?.data.errors) {
            handleSnackBar('Erro ao editar egresso!', 'error')
          } else {
            setErrors(error.response?.data.errors)
          }
        }
      }
    }
  })
  const { errors, values, setErrors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik

  return (
    <Container>
      {!inProfile && (
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Egressos
          </Typography>
        </Stack>
      )}
      <Card style={{ padding: '30px' }}>
        {reqFailed && (
          <Alert variant="filled" severity="error" style={{ marginBottom: '32px' }}>
            Não foi possível carregar o egresso, tente novamente mais tarde.
          </Alert>
        )}
        {!loading && !meLoading ? (
          <FormikProvider value={formik}>
            {reqErr && (
              <Alert variant="filled" severity="error" style={{ marginBottom: '32px' }}>
                Ocorreu um erro inesperado, por favor tente novamente mais tarde.
              </Alert>
            )}

            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Nome Completo"
                    {...getFieldProps('full_name')}
                    inputProps={{ type: 'string', value: fullName }}
                    error={Boolean(touched?.full_name && errors?.full_name)}
                    helperText={touched?.full_name && <span dangerouslySetInnerHTML={{ __html: errors?.full_name }} />}
                    onChange={(e) => {
                      setFieldValue('full_name', e.target.value)
                      setFullName(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    type="email"
                    label="E-mail"
                    inputProps={{ type: 'string', value: email }}
                    {...getFieldProps('email')}
                    error={Boolean(touched?.email && errors?.email)}
                    helperText={touched?.email && <span dangerouslySetInnerHTML={{ __html: errors?.email }} />}
                    onChange={(e) => {
                      setFieldValue('email', e.target.value)
                      setEmail(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    inputProps={{ type: 'number', value: mestrado }}
                    label="Ano de formação no mestrado em Entomologia-UFV"
                    {...getFieldProps('masters_degree_year')}
                    error={Boolean(touched?.masters_degree_year && errors?.masters_degree_year)}
                    helperText={
                      touched?.masters_degree_year && (
                        <span dangerouslySetInnerHTML={{ __html: errors?.masters_degree_year }} />
                      )
                    }
                    onChange={(e) => {
                      setFieldValue('masters_degree_year', e.target.value)
                      setMestrado(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    inputProps={{ type: 'number', value: doutorado }}
                    label="Ano de formação no doutorado em Entomologia-UFV"
                    {...getFieldProps('doctorate_degree_year')}
                    error={Boolean(touched?.doctorate_degree_year && errors?.doctorate_degree_year)}
                    helperText={
                      touched?.doctorate_degree_year && (
                        <span dangerouslySetInnerHTML={{ __html: errors?.doctorate_degree_year }} />
                      )
                    }
                    onChange={(e) => {
                      setFieldValue('doctorate_degree_year', e.target.value)
                      setDoutorado(e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Emprego Atual</Typography>
                </Grid>
                {Boolean(isRetired) && (
                  <Grid item>
                    <Alert severity="info">
                      As informações abaixo são referentes ao seu último local de trabalho onde se aposentou.
                    </Alert>
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Área</InputLabel>
                    <Select
                      labelId="age-select-label"
                      id="age-select"
                      label="Age"
                      onChange={(e) => {
                        if (e.target.value === null) {
                          setIsEmployed('no')
                          setJobCity('')
                          setJobCompany('')
                          setJobState('')
                          setJobCountry('')
                          setFieldValue('job_company', '')
                          setFieldValue('job_city', '')
                          setFieldValue('job_state', '')
                          setFieldValue('job_country', '')
                          setJobArea('')
                          setIsRetired(0)
                          setFieldValue('lat', 0)
                          setFieldValue('lng', 0)
                        } else {
                          setIsEmployed('yes')
                          setFieldValue('job_area', e.target.value)
                          setJobArea(e.target.value)
                        }
                      }}
                      error={Boolean(touched?.job_area && errors?.job_area)}
                      value={jobArea}
                      defaultValue={jobArea}
                    >
                      <MenuItem value={null}>
                        <em>Nenhum</em>
                      </MenuItem>
                      <MenuItem value="PÚBLICA">Pública</MenuItem>
                      <MenuItem value="PRIVADA">Privada</MenuItem>
                    </Select>
                    {errors?.job_area && (
                      <FormHelperText error id="my-helper-text">
                        <span dangerouslySetInnerHTML={{ __html: errors?.job_area }} />
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled={jobArea === ''}
                    fullWidth
                    inputProps={{ type: 'string', value: jobCompany }}
                    label="Empresa ou Instituição"
                    {...getFieldProps('job_company')}
                    onChange={(e) => {
                      setFieldValue('job_company', e.target.value)
                      setJobCompany(e.target.value)
                    }}
                    error={Boolean(touched?.job_company && errors?.job_company)}
                    helperText={
                      touched?.job_company && <span dangerouslySetInnerHTML={{ __html: errors?.job_company }} />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <GoogleSearch
                    disabled={jobArea === ''}
                    key={jobCountry}
                    edit
                    jobCity={jobCity}
                    jobState={jobState}
                    jobCountry={jobCountry}
                    setFieldValue={setFieldValue}
                    error={Boolean(errors?.job_country) || Boolean(errors?.job_city)}
                    helperText={
                      errors?.job_country ||
                      (errors?.job_city && (
                        <span dangerouslySetInnerHTML={{ __html: errors?.job_country || errors?.job_city }} />
                      ))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={jobArea === ''}
                          checked={Boolean(isRetired)}
                          onChange={(e) => handleRetired(e)}
                        />
                      }
                      label="Aposentado"
                    />
                  </FormGroup>
                </Grid>
                {inProfile && (
                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={Boolean(allowDisplay)} onChange={handlePermission} />}
                        label="Permito a exibição dos meus dados publicamente"
                      />
                    </FormGroup>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="space-between">
                    {!inProfile ? (
                      <FormControlLabel
                        control={<Switch checked={Boolean(status)} onChange={() => setStatus(status === 0 ? 1 : 0)} />}
                        label="Registro Aprovado"
                      />
                    ) : (
                      <Grid item xs={12} />
                    )}

                    <LoadingButton
                      size="large"
                      type="submit"
                      variant="contained"
                      disabled={!formik.isValid}
                      loading={isSubmitting}
                    >
                      Salvar
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        ) : (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        )}
      </Card>
    </Container>
  )
}
