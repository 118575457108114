import Api from '../../../../api/api'

export const getEgressosById = async (id) => {
  const egresso = await Api.get(`/egressos/${id}`)
  return egresso
}

export const getEgressos = async (page = 1, perPage, orderBy, orderDir, term) => {
  const egressos = await Api.get(
    `/egressos?page=${page}&per_page=${perPage}&orderBy=${orderBy}&orderDir=${orderDir}&term=${term}`
  )
  return egressos.data
}

export const deleteEgresso = async (id) => {
  const del = await Api.delete(`/egressos/${id}`)
  return del
}

export const editEgresso = async (id, newValue) => {
  const edit = await Api.put(`egressos/${id}`, newValue)
  return edit
}

export const updatePersonalEgresso = async (data) => {
  const personal = await Api.put(`/me/personal`, data)
  return personal
}
