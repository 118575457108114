/* eslint-disable camelcase */
import Api from './api'

export const registerEgresso = async (data) => {
  const resp = await Api.post('/register', data)
  return resp
}

export const login = async (email, password) => {
  const resp = await Api.post('/auth', { email, password })
  return resp.data
}

export const forgot = async (email) => {
  const resp = await Api.post(`/password/forgot/${email}`)
  return resp.data
}

export const redefine = async (email, token) => {
  const resp = await Api.post(`/password/reset`, { email, token })
  return resp.data
}
