import { CircularProgress, Grid } from '@mui/material'
import React from 'react'

const LoadingPage = () => {
  return (
    <div>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <CircularProgress />
      </Grid>
    </div>
  )
}

export default LoadingPage
