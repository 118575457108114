import * as Yup from 'yup'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik, Form, FormikProvider } from 'formik'
// material
import { Stack, TextField, Alert } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { forgot, redefine } from '../../../api/auth'
// ----------------------------------------------------------------------

export default function ForgotForm() {
  const navigate = useNavigate()
  const [credentialsError, setCredentialsError] = useState(false)

  const ForgotSchema = Yup.object().shape({
    email: Yup.string().email('O e-mail deve ser um endereço de e-mail válido').required('E-mail obrigatório')
  })
  const formik = useFormik({
    initialValues: {
      email: '',
      remember: true
    },
    validationSchema: ForgotSchema,
    onSubmit: async (values) => {
      const { email } = values
      setCredentialsError(false)
      try {
        const resp = await forgot(email)
        const { token } = resp

        await redefine(email, token)
        navigate('/nova-senha-enviada', { state: { email }, replace: true })
      } catch (error) {
        if ([400, 401, 404].includes(error?.response?.status)) {
          setCredentialsError(true)
        }
      }
    }
  })

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik

  return (
    <FormikProvider value={formik}>
      {credentialsError && (
        <Alert variant="filled" severity="error" style={{ margin: '32px 0' }}>
          Não foi possível redefinir a senha. Verifique seu e-mail ou tente novamente em instantes.
        </Alert>
      )}
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ mb: 3 }}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="E-mail"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Enviar
        </LoadingButton>
      </Form>
    </FormikProvider>
  )
}
