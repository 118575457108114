import * as Yup from 'yup'
import React, { useEffect, useState } from 'react'
import { useFormik, Form, FormikProvider } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { Card, Stack, Container, Typography, CircularProgress, TextField, Alert, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import Page from '../../Page'

import { createUser, updateUser, updateUserAccess } from './requests'
import { useMe } from '../../../Context/MeContext'

const UserForm = ({ inProfile = false }) => {
  const location = useLocation()
  const { userInfo, meLoading, setRefresh } = useMe()
  const navigate = useNavigate()
  const [fullName, setFullName] = useState(location.state?.row.name || '')
  const [email, setEmail] = useState(location.state?.row.email || '')
  const [password, setPassword] = useState('')
  const [cPassword, setCPassword] = useState('')

  // Populate the form with the user's data
  useEffect(() => {
    if (inProfile) {
      setFullName(userInfo.name)
      setFieldValue('name', userInfo.name)
      setEmail(userInfo.email)
      setFieldValue('email', userInfo.email)
    }
  }, [userInfo])

  const [reqErr, setReqErr] = useState(false)

  // Toast de success/error
  const { enqueueSnackbar } = useSnackbar()
  const handleSnackBar = (message, variant) => {
    enqueueSnackbar(message, {
      variant
    })
  }

  // Shape of the form
  const EditSchema = Yup.object().shape({
    name: Yup.string().required('Por favor informe o nome completo do usuário').nullable(),
    email: Yup.string()
      .email('O e-mail deve ser um endereço de e-mail válido')
      .required('Por favor informe o e-mail do usuário'),
    password: Yup.string().min(6, 'A senha deve ter no mínimo 6 caracteres'),
    c_password: Yup.string().when('password', {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string()
        .oneOf([Yup.ref('password')], 'As senhas não conferem')
        .required('Por favor confirme a senha')
    })
  })

  // Check if the form is in edit mode or create mode and submit the form
  const formik = useFormik({
    initialValues: {
      name: fullName,
      email
    },
    validationSchema: EditSchema,
    onSubmit: async () => {
      // Check if the form is in profile mode and update the user's data
      if (inProfile) {
        try {
          await updateUserAccess(values)
          setRefresh((prev) => !prev)
          handleSnackBar('Acesso atualizado com sucesso!', 'success')
          setPassword('')
          setCPassword('')
          setFieldValue('password', '')
          setFieldValue('c_password', '')
          return
        } catch (error) {
          setErrors(error.response.data.errors)
        }
      }
      // Check if the form is in edit mode and update the user's data
      if (location.state?.userId) {
        try {
          await updateUser(location.state.userId, values)
          handleSnackBar('Usuário editado com sucesso!', 'success')
          navigate('/app/usuarios', { state: { page: location?.state?.page }, replace: true })
        } catch (error) {
          if (error.response.data.errors) {
            setErrors(error.response.data.errors)
          } else {
            handleSnackBar('Erro ao editar usuário!', 'error')
            setReqErr(true)
          }
        }
        return
      }
      // Check if the form is in create mode and create the user
      try {
        await createUser(values)
        handleSnackBar('Usuário criado com sucesso!', 'success')
        navigate('/app/usuarios')
      } catch (error) {
        if (error.response.data.errors) {
          setErrors(error.response.data.errors)
        } else {
          handleSnackBar('Erro ao criar usuário!', 'error')
          setReqErr(true)
        }
      }
      setReqErr(false)
    }
  })

  // Button with text based on the form's mode
  const printButtonText = () => {
    if (location.state?.userId) {
      return 'Editar'
    }
    if (inProfile) {
      return 'Salvar'
    }
    return 'Criar'
  }

  const { errors, values, setErrors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik
  return (
    <Page title="Criar Egresso | Portal Egressos - UFV">
      <Container>
        {!inProfile && (
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Usuários
            </Typography>
          </Stack>
        )}
        <Card style={{ padding: '30px' }}>
          {!meLoading ? (
            <FormikProvider value={formik}>
              {reqErr && (
                <Alert variant="filled" severity="error" style={{ marginBottom: '32px' }}>
                  Ocorreu um erro inesperado, por favor tente novamente mais tarde.
                </Alert>
              )}

              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Nome Completo"
                      {...getFieldProps('name')}
                      inputProps={{ type: 'string', value: fullName }}
                      error={Boolean(touched?.name && errors?.name)}
                      helperText={touched?.name && <span dangerouslySetInnerHTML={{ __html: errors?.name }} />}
                      onChange={(e) => {
                        setFieldValue('name', e.target.value)
                        setFullName(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="email"
                      label="E-mail"
                      inputProps={{ type: 'string', value: email }}
                      {...getFieldProps('email')}
                      error={Boolean(touched?.email && errors?.email)}
                      helperText={touched?.email && <span dangerouslySetInnerHTML={{ __html: errors?.email }} />}
                      onChange={(e) => {
                        setFieldValue('email', e.target.value)
                        setEmail(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      inputProps={{ type: 'password', value: password }}
                      label="Senha"
                      {...getFieldProps('password')}
                      error={Boolean(touched?.password && errors?.password)}
                      helperText={touched?.password && <span dangerouslySetInnerHTML={{ __html: errors?.password }} />}
                      onChange={(e) => {
                        if (e.target.value === '') {
                          setFieldValue('c_password', '')
                          setCPassword('')
                        }
                        setFieldValue('password', e.target.value)
                        setPassword(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      inputProps={{ type: 'password', value: cPassword }}
                      label="Confirmar senha"
                      {...getFieldProps('c_password')}
                      error={Boolean(touched?.c_password && errors?.c_password)}
                      helperText={
                        touched?.c_password && <span dangerouslySetInnerHTML={{ __html: errors?.c_password }} />
                      }
                      onChange={(e) => {
                        setFieldValue('c_password', e.target.value)
                        setCPassword(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between">
                      <LoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                        /* disabled={!formik.isValid} */
                        loading={isSubmitting}
                      >
                        {printButtonText()}
                      </LoadingButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          ) : (
            <Stack alignItems="center">
              <CircularProgress />
            </Stack>
          )}
        </Card>
      </Container>
    </Page>
  )
}

export default UserForm
