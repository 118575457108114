import React from 'react'
import { Link, Typography } from '@mui/material'

const Signature = () => {
  return (
    <Typography variant="caption" align="center" color="GrayText" paddingTop={0.5}>
      Desenvolvido com carinho pela{' '}
      <Link target="_blank" href="https://aldesenvolvimento.com.br/">
        <img
          style={{ display: 'inline', padding: '0 4px' }}
          width={130}
          src="/static/logo_aldesenvolvimento.png"
          alt="Logo AL Desenvolvimento"
        />
      </Link>
    </Typography>
  )
}

export default Signature
