import { IconButton, Tooltip } from '@mui/material'
import ExploreIcon from '@mui/icons-material/Explore'
import { styled } from '@mui/material/styles'

const LocateButton = styled(IconButton)(() => ({
  position: 'absolute',
  bottom: '7rem',
  right: '0.7rem',
  border: 'none',
  zIndex: 10,
  color: '#666',
  background: 'white',
  '&:hover': { background: 'white', color: 'black' }
}))

const Locate = ({ panTo }) => {
  return (
    <Tooltip title="Mostrar seu local">
      <LocateButton
        onClick={() => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              // Set the lat and lng of the user in localStorage and pan the map
              localStorage.setItem(
                'location',
                JSON.stringify({ lat: position.coords.latitude, lng: position.coords.longitude })
              )
              panTo({ lat: position.coords.latitude, lng: position.coords.longitude })
            },
            () => null
          )
        }}
      >
        <ExploreIcon />
      </LocateButton>
    </Tooltip>
  )
}

export default Locate
