import { Icon } from '@iconify/react'
import mapIcon from '@iconify/icons-mdi/map'
// material
import { alpha, styled } from '@mui/material/styles'
import { Card, Typography } from '@mui/material'

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  padding: theme.spacing(8, 0),
  color: 'white',
  backgroundColor: '#007B55'
}))

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: 'transparent'
}))

// ----------------------------------------------------------------------

export default function Mapa() {
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={mapIcon} width={64} height={64} color="white" />
      </IconWrapperStyle>
      <Typography variant="h3">Mapa</Typography>
    </RootStyle>
  )
}
