import PropTypes from 'prop-types'
// material
import { Box } from '@mui/material'

// ----------------------------------------------------------------------

LogoBrasaoUFV.propTypes = {
  sx: PropTypes.object
}

export default function LogoBrasaoUFV({ sx }) {
  return <Box component="img" src="/static/brasaoUFV.png" sx={{ width: 280, ...sx }} />
}
