import { Link as RouterLink } from 'react-router-dom'
// material
import { styled } from '@mui/material/styles'
import { Link, Container, Typography } from '@mui/material'
// components
import Page from '../components/Page'
import { MHidden } from '../components/@material-extend'
import { RegisterForm } from '../components/authentication/register'
import AuthSidebar from '../layouts/AuthSidebar'
// import AuthSocial from '../components/authentication/AuthSocial'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}))

// ----------------------------------------------------------------------

export default function Register() {
  return (
    <RootStyle title="Registrar | Portal Egressos - UFV">
      <AuthSidebar>
        <Typography variant="h1" sx={{ px: 10 }} align="center">
          Cadastro de <br /> Egresso
        </Typography>
      </AuthSidebar>

      <Container>
        <ContentStyle>
          <RegisterForm />

          <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
            Já tem uma conta? &nbsp;
            <Link to="/login" component={RouterLink}>
              Entrar
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  )
}
